<template>
  <div id="app-menu" :class="{ 'menu-alternative-height': alterarTamanhoMenu }" v-if="!this.config.MENU_NOVO">
    <!-- Botões de escanear -->
    <div
      v-if="showMyMenuActions"
      id="myMenuActions"
      class="menu-actions"
      :class="{
        'justify-content-around':
          config.DIGITAR_CUPOM_MANUALMENTE || isDesktop || !config.EXIBIR_BOTAO_ESCANEAR_QR_CODE,
      }"
    >
      <!-- Escanear QRCode -->
      <base-pill-button
        v-if="
          (!config.DIGITAR_CUPOM_MANUALMENTE || config.HABILITAR_CADASTRO_CUPONS_DOCUMENTOS_FISCAIS_SIMULTANEOS) &&
            !isDesktop &&
            config.EXIBIR_BOTAO_ESCANEAR_QR_CODE &&
            !config.HABILITAR_LANCAMENTO_MANUAL
        "
        class="pt-3 mr-2"
        :type="'light'"
        :left-middle-pill="'fas fa-qrcode'"
        :pillColors="['pill-dark']"
        :pillIconColors="['icon-light']"
        :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-average']"
        @click="
          block
            ? campaignMsg('Não há campanhas promocionais disponíveis no momento!', false)
            : checkCupom({ nomeDaRota: 'escanear', navegacaoAnimada: false })
        "
      >
        <template v-slot:pill-button-title>
          <span class=" pl-2 pr-2 text-center f-07 text-black">
            <strong
              :style="
                config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES
                  ? `color: ${config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES} !important;`
                  : ''
              "
            >
              Escanear QRCode
            </strong>
          </span>
        </template>
      </base-pill-button>

      <!-- Redirecionamento para tela dedigitacao manual -->
      <base-pill-button
        v-if="
          (!config.DIGITAR_CUPOM_MANUALMENTE || config.HABILITAR_CADASTRO_CUPONS_DOCUMENTOS_FISCAIS_SIMULTANEOS) &&
            !config.HABILITAR_LANCAMENTO_MANUAL
        "
        class="pt-3"
        :type="'light'"
        :left-middle-pill="'fas fa-file-alt'"
        :pillColors="['pill-dark']"
        :pillIconColors="['icon-light']"
        :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="larguraJanela > 1024 ? ['pill-large'] : ['pill-average']"
        @click="
          !block
            ? checkCupom({ nomeDaRota: 'digitacaoManual', navegacaoAnimada: false })
            : campaignMsg('Não há campanhas promocionais disponíveis no momento!', false)
        "
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-2 pr-2 text-center f-07 text-black">
            <strong
              :style="
                config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES
                  ? `color: ${config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES} !important;`
                  : ''
              "
            >
              {{ config.TEXTO_BOTAO_CADASTRO_CUPOM_FISCAL }}
            </strong>
          </span>
        </template>
      </base-pill-button>

      <!-- Redirecionamento para tela de cadastrar doc fiscal manual -->
      <base-pill-button
        v-if="
          (config.DIGITAR_CUPOM_MANUALMENTE ||
            config.HABILITAR_CADASTRO_CUPONS_DOCUMENTOS_FISCAIS_SIMULTANEOS ||
            config.HABILITAR_CADASTRO_COMPRA_POR_QUANTIDADE) &&
            !config.HABILITAR_LANCAMENTO_MANUAL
        "
        class="pt-3"
        :type="'light'"
        :left-middle-pill="'fas fa-file-alt'"
        :pillColors="['pill-dark']"
        :pillIconColors="['icon-light']"
        :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-large']"
        @click="
          !block
            ? checkCupom({
                nomeDaRota: config.HABILITAR_CADASTRO_COMPRA_POR_QUANTIDADE ? 'cadastroCompra' : 'cadastroManual',
                navegacaoAnimada: true,
              })
            : campaignMsg('Não há campanhas promocionais disponíveis no momento!', false)
        "
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-2 pr-2 text-center f-07 text-black">
            <strong
              :style="
                config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES
                  ? `color: ${config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES} !important;`
                  : ''
              "
            >
              {{ config.TEXTO_BOTAO_CADASTRO_DOCUMENTO_FISCAL }}
            </strong>
          </span>
        </template>
      </base-pill-button>

      <!-- Redirecionamento para a tela de lançamento manual -->
      <!-- Se ativo, desabilita todos os outros botões acima (por enquanto) -->
      <base-pill-button
        v-if="config.HABILITAR_LANCAMENTO_MANUAL"
        class="pt-3"
        :type="'light'"
        :left-middle-pill="'fas fa-file-alt'"
        :pillColors="['pill-dark']"
        :pillIconColors="['icon-light']"
        :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-small']"
        @click="
          !block
            ? checkCupom({ nomeDaRota: 'lancamentoManual', navegacaoAnimada: true })
            : campaignMsg('Não há campanhas promocionais disponíveis no momento!', false)
        "
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-2 pr-2 text-center f-07 text-black">
            <strong
              :style="
                config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES
                  ? `color: ${config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES} !important;`
                  : ''
              "
            >
              {{ config.TEXTO_BOTAO_CADASTRO_CUPOM_FISCAL }}
            </strong>
          </span>
        </template>
      </base-pill-button>
    </div>

    <!-- Botões de navegação -->
    <div id="menu-slide" class="menu-slide">
      <app-menu-item
        :background="
          config.FORCAR_CORES_MENU
            ? config.TABELA_COR.find(cor => cor.nome == 'COR_BACKGROUND_FORCADO_BOTOES').cor
            : 'transparent'
        "
        :textColor="
          config.FORCAR_CORES_MENU
            ? config.TABELA_COR.find(cor => cor.nome == 'COR_TEXTO_FORCADO_BOTOES').cor
            : 'initial'
        "
        v-for="r in retornaTelasConfiguraveis"
        :key="r.destino"
        :label="r.label"
        :icon="r.icone"
        :destination="r.destino"
        :navBold="config.NAVEGACAO_NEGRITO ? true : false"
      />
    </div>
  </div>
  <div id="app-menu" class="menu-novo" :class="{ 'menu-alternative-height': alterarTamanhoMenu }" v-else>
    <div id="menu-slide" class="menu-slide">
      <app-menu-item
        textColor="var(--color-secondary-text-button)"
        v-for="(r, index) in retornaTelasConfiguraveis"
        :key="r.destino"
        :label="r.label"
        :icon="r.icone"
        :destination="r.destino"
        :index="index"
        :navBold="config.NAVEGACAO_NEGRITO ? true : false"
        @openFinishSignUpModal="abrirModalCompletarCadastro"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { Data } from '../../../lib/Helper';
import { config } from '../../../lib/Storage/src/storeConfig';

export default {
  name: 'app-menu',

  props: ['windowSize', 'campaign', 'isDesktop' /* , 'isProd' */],

  data: () => ({
    block: false,
    mensagemLimite: '',
    larguraJanela: null,
  }),

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      perfil: 'consumidor/getProfile',
      telasConfiguraveis: 'config/obterTelasConfiguraveis',
      isComper: 'integracaoComper/getIsComper',
      cupomConfig: 'cupom/getCouponsConfig',
      raspadinhasAltConfig: 'raspadinhaAlternativa/getRaspadinhasAltConfig',
      documentosFiscais: 'documentoFiscal/getDocumentosFiscais',
      configuracaoDocumentosFiscais: 'documentoFiscal/getConfiguracaoDocumentosFiscais',
      quantidadeCupons: 'cupom/getCuponsEscaneadosPorPeriodo',
      quantidadeRaspadinhas: 'raspadinhaAlternativa/getRaspadinhasPorPeriodo',
      totalCupomEscaneadoPorLote: 'cupom/getTotalCupomPorLote',
    }),

    alterarTamanhoMenu() {
      return ['homeProduto', 'filtrosProduto', 'detalhesProduto'].includes(this.$route.name);
    },

    retornaTelasConfiguraveis() {
      const listaAtual = this.telasConfiguraveis.filter(c => {
        if (c.destino === 'vitrineProdutos') {
          if (this.config.HABILITAR_VITRINE_PRODUTOS_FIDELIDADE == true) {
            return c;
          }
        } else {
          return c;
        }
      });

      return listaAtual;
    },

    showMyMenuActions() {
      if (!this.campaign) {
        return true;
      } else {
        if (this.config.CLIENTE_USA_INTEGRACAO) return false;
        else return true;
      }
    },

    isProd: function() {
      if (typeof BuildInfo != 'undefined' && BuildInfo.packageName) {
        return this.config.VUE_APP_PROD_PACK_NAME == BuildInfo.packageName;
      } else {
        return location.href.startsWith(this.config.VUE_APP_PROD_BASE_URL);
      }
    },
  },

  methods: {
    ...mapMutations({
      setIsIntegracaoComper: 'integracaoComper/setIsIntegracaoComperMutation',
    }),

    ...mapActions({
      obterQuantidadeCuponsPeriodo: 'cupom/obterQuantidadeCuponsPeriodo',
      obterQuantidadeRaspadinhasPeriodo: 'raspadinhaAlternativa/obterQuantidadeRaspadinhasPeriodo',
      fetchTotalCupomEscaneadoPorLote: 'cupom/fetchTotalCupomEscaneadoPorLote',
    }),

    retornarConfiguracaoPremiacaoInstantanea(nome) {
      if (this.config.OBJETO_CONFIGURACAO_PREMIACAO_INSTANTANEA) return null;

      const CONFIGURACAO_ENCONTRADA = this.config.OBJETO_CONFIGURACAO_PREMIACAO_INSTANTANEA.find(objPremiacao =>
        objPremiacao.nome.match(nome)
      );

      if (CONFIGURACAO_ENCONTRADA) return CONFIGURACAO_ENCONTRADA.valor;
      return null;
    },

    nomePremiacao(plural = true) {
      if (plural) {
        const nomePremiacao = this.retornarConfiguracaoPremiacaoInstantanea('NOME_PREMIACAO_PLURAL');

        if (!nomePremiacao) {
          if (this.config.ROLETA_PREMIADA) return 'giros da sorte';
          else if (this.config.NOME_RASPADINHA) return `${this.config.NOME_RASPADINHA}s`;

          return 'raspadinhas';
        }
      }

      const nomePremiacao = this.retornarConfiguracaoPremiacaoInstantanea('NOME_PREMIACAO_SINGULAR');

      if (!nomePremiacao) {
        if (this.config.ROLETA_PREMIADA) return 'giro da sorte';
        else if (this.config.NOME_RASPADINHA) return this.config.NOME_RASPADINHA;

        return 'raspadinha';
      }
    },

    animatedNavigateTo(route) {
      this.$emit('emmitEvent', { event: 'campaignAnimation' });
      this.$router.push({ name: route });
    },

    navigateTo(route) {
      this.$router.push({ name: route });
    },

    async perguntarENavegar({ nomeDaRota, navegacaoAnimada = false }) {
      /**
       * ESQUEMA DA INTEGRAÇÃO DA COMPER
       * 1 - PERGUNTA SE É LOJA FÍSICA OU ECOMMERCE
       *
       * CASO TENHA SIDO LOJA FÍSICA
       *    1 - PERGUNTA O ESTADO
       *    CASO SEJA SC VAI PRA TELA NOVA DE ESCANEARCOMPER
       *    CASO SEJA OUTRO ESTADO VAI PRA TELA QUE O USUÁRIO QUER
       *
       * CASO TENHA SIDO ECOMMERCE
       *    1 - PERGUNTA O ESTADO ATOA MESMO
       *    E AI VAI TELA DE DIGITAÇÃO MANUAL
       */

      if (this.isComper) {
        const respostaLojaFisicaOuEcommerce = await this.perguntaIntegracaoComper();

        if (respostaLojaFisicaOuEcommerce == 'lojaFisica') {
          const respostaEstado = await this.perguntaEstado();

          if (respostaEstado == 'sc') {
            this.navigateTo('escanearCupomComper');
          } else {
            this.setIsIntegracaoComper(false);
            navegacaoAnimada ? this.animatedNavigateTo(nomeDaRota) : this.navigateTo(nomeDaRota);
          }
        } else if (respostaLojaFisicaOuEcommerce == 'ecommerce') {
          this.setIsIntegracaoComper(true);
          this.navigateTo('digitacaoManual');
        }
      } else {
        navegacaoAnimada ? this.animatedNavigateTo(nomeDaRota) : this.navigateTo(nomeDaRota);
      }
    },

    abrirModalCompletarCadastro() {
      this.$emit('emmitModalEvent', {
        event: 'openFinishSignUpModal',
      });
    },

    limitarConfiguracao() {
      this.mensagemLimite = '';
      let limiteAtingido = false;

      if (this.configuracaoDocumentosFiscais && this.configuracaoDocumentosFiscais.configuracoes) {
        const naoFiscal = this.configuracaoDocumentosFiscais.configuracoes.find(e => e.tipo == 'NaoFiscal');
        if (
          naoFiscal &&
          typeof naoFiscal.quantidadeLimite == 'number' &&
          naoFiscal.quantidadeLimite !== -1 &&
          this.documentosFiscais &&
          this.documentosFiscais.length >= naoFiscal.quantidadeLimite
        ) {
          limiteAtingido = true;
        }

        if (naoFiscal.quantidadeLimite == 0) {
          this.mensagemLimite = `Não liberado para cadastrar uma compra!`;
        } else if (this.documentosFiscais.length >= naoFiscal.quantidadeLimite) {
          let substantivo = naoFiscal.quantidadeLimite > 1 ? 'documentos fiscais' : 'documento fiscal';
          this.mensagemLimite = `Limite de ${naoFiscal.quantidadeLimite} ${substantivo} atingido!`;
        }
      }
      return limiteAtingido;
    },

    async limitarConfiguracaoCupom() {
      await this.carregarQuantidadeCuponsPeriodo();

      this.mensagemLimite = '';
      let limiteAtingido = false;

      if (this.cupomConfig.hasOwnProperty('limitesPorUsuario') && this.quantidadeCupons) {
        const limitesHabilitados = Object.entries(this.cupomConfig.limitesPorUsuario).filter(
          ([_, quantidade]) => quantidade != -1 && quantidade != 0
        );

        if (!limitesHabilitados.length) limiteAtingido = false;

        const cuponsDisponiveisPorLimite = limitesHabilitados.map(([tipo, quantidade]) => ({
          tipo,
          quantidade: quantidade - this.quantidadeCupons[tipo],
        }));

        limiteAtingido = cuponsDisponiveisPorLimite.some(e => e.quantidade < 1);

        if (limiteAtingido) {
          const cuponsRestantes = Math.min(...cuponsDisponiveisPorLimite.map(e => e.quantidade));
          const tipoLimite = cuponsDisponiveisPorLimite.find(e => e.quantidade === cuponsRestantes).tipo;
          let substantivo = this.cupomConfig.limitesPorUsuario[tipoLimite] > 1 ? 'cupons' : 'cupom';
          this.mensagemLimite = `Limite ${tipoLimite} de ${this.cupomConfig.limitesPorUsuario[tipoLimite]} ${substantivo} atingido!`;
        }
      }

      return limiteAtingido;
    },

    async verificarTotalCupomEscaneadoPorLoteCampanha() {
      await this.carregaTotalCupomEscaneadoPorLote();

      const totalCuponsCampanha = this.campaign.totalCupons;
      const totalCuponsEscaneados = this.retornaTotalCuponsPorLote();

      // return true;
      if (totalCuponsCampanha === totalCuponsEscaneados) {
        return true;
      }
    },

    retornaTotalCuponsPorLote() {
      const somaDosCuponsEscaneados = this.totalCupomEscaneadoPorLote.reduce((valorAcumulado, valorAtual) => {
        return valorAcumulado + valorAtual.totalEscaneados;
      }, 0);

      return somaDosCuponsEscaneados;
    },

    async limitarConfiguracaoRaspadinha() {
      if (!this.quantidadeRaspadinhas) await this.carregarQuantidadeRaspadinhasPeriodo();

      this.mensagemLimite = '';
      let limiteAtingido = false;

      if (this.raspadinhasAltConfig.hasOwnProperty('limitesPorUsuario') && this.quantidadeRaspadinhas) {
        const limitesHabilitados = Object.entries(this.raspadinhasAltConfig.limitesPorUsuario).filter(
          ([_, quantidade]) => quantidade != -1 && quantidade != 0
        );

        if (!limitesHabilitados.length) limiteAtingido = false;

        const raspadinhasDisponiveisPorLimite = limitesHabilitados.map(([tipo, quantidade]) => ({
          tipo,
          quantidade: quantidade - this.quantidadeRaspadinhas[tipo],
        }));

        limiteAtingido = raspadinhasDisponiveisPorLimite.some(e => e.quantidade < 1);

        if (limiteAtingido) {
          const raspadinhasRestantes = Math.min(...raspadinhasDisponiveisPorLimite.map(e => e.quantidade));
          const tipoLimite = raspadinhasDisponiveisPorLimite.find(e => e.quantidade === raspadinhasRestantes).tipo;
          let substantivo =
            this.raspadinhasAltConfig.limitesPorUsuario[tipoLimite] > 1
              ? this.nomePremiacao(true)
              : this.nomePremiacao(false);
          this.mensagemLimite = `Limite ${tipoLimite} de ${this.raspadinhasAltConfig.limitesPorUsuario[tipoLimite]} ${substantivo} atingido!`;
        }
      }

      return limiteAtingido;
    },

    async checkCupom({ nomeDaRota, navegacaoAnimada = false }) {
      if (this.perfil && this.perfil.cadastroInicial) {
        this.$emit('openFinishSignUpModal', true);
      } else if (['cadastroCompra', 'cadastroManual'].includes(nomeDaRota) && this.limitarConfiguracao()) {
        this.$swal({
          type: 'error',
          title: 'Ops...',
          text: this.mensagemLimite || `Limite de documentos fiscais atingido!`,
        });
      } else if (['cadastroCompra', 'cadastroManual'].includes(nomeDaRota) && (await this.limitarConfiguracaoCupom())) {
        this.$swal({
          type: 'error',
          title: 'Ops...',
          text: this.mensagemLimite || `Limite de cupons atingido!`,
        });
      } else if (
        ['cadastroCompra', 'cadastroManual', 'digitacaoManual'].includes(nomeDaRota) &&
        (await this.verificarTotalCupomEscaneadoPorLoteCampanha())
      ) {
        this.$swal({
          type: 'warning',
          title: 'Ops...',
          text:
            'Nossa campanha foi um sucesso e todos os números da sorte foram distribuídos 😊 Campanha encerrada: Consulte as regras do regulamento.',
        });
      } else {
        this.perguntarENavegar({ nomeDaRota, navegacaoAnimada });
      }
    },

    async perguntaEstado() {
      const { value: resposta } = await this.$swal({
        type: 'question',
        title: 'Selecione o estado onde realizou sua compra',
        input: 'select',
        inputOptions: {
          df: 'Distrito Federal',
          go: 'Goiás',
          ms: 'Mato Grosso do Sul',
          mt: 'Mato Grosso',
          sc: 'Santa Catarina',
        },
        inputPlaceholder: 'Selecione seu estado',
        allowOutsideClick: false,
      });

      return resposta;
    },

    async perguntaIntegracaoComper() {
      const resposta = await this.$swal({
        type: 'question',
        title: 'Onde você realizou sua compra?',
        confirmButtonText: 'Loja física',
        showCancelButton: true,
        cancelButtonText: 'Loja virtual (e-commerce)',
        allowOutsideClick: false,
      });

      const { value, dismiss } = resposta;

      if (value) {
        return 'lojaFisica';
      } else if (dismiss && dismiss == 'cancel') {
        return 'ecommerce';
      }
    },

    carregaCampanhas: async function() {
      const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;
      const currentDate = Data.dataHoraAtual();
      const url = `${wsUrl}/campanha/v1/campanhas/`;
      let campanhaRecuperada = null;

      try {
        const res = await fetch(url);
        const data = await res.json();

        data.retorno
          .filter(camp => {
            let initial = `${camp.dataInicial} 00:00:00`;
            let final = `${camp.dataFinal} 23:59:00`;
            return camp.ativa && currentDate >= initial && currentDate <= final;
          })
          .forEach(camp => {
            campanhaRecuperada = camp;
          });
        this.block = campanhaRecuperada ? false : true;
      } catch (error) {
        console.log('Error:', error);
        this.block = true;
        this.campaignMsg(
          'Não foi possível recuperar campanhas promocionais disponíveis no momento, verifique sua conexão com a internet.',
          false
        );
      }

      // await fetch(url)
      //   .then(response => response.json())
      //   .then(async data => {
      //     data.retorno
      //       .filter(camp => {
      //         let initial = `${camp.dataInicial} 00:00:00`;
      //         let final = `${camp.dataFinalCompras} 23:59:00`;
      //         return camp.ativa && currentDate >= initial && currentDate <= final;
      //       })
      //       .forEach(camp => {
      //         campanhaRecuperada = camp;
      //       });
      //     this.block = campanhaRecuperada ? false : true;
      //     console.log(this.block);

      //     await this.carregarQuantidadeCuponsPeriodo();
      //     await this.carregarQuantidadeRaspadinhasPeriodo();
      //   })
      //   .catch(error => {
      //     console.error('Error:', error);
      //     this.block = true;
      //     this.campaignMsg(
      //       'Não foi possível recuperar campanhas promocionais disponíveis no momento, verifique sua conexão com a internet.',
      //       false
      //     );
      //   });
    },

    campaignMsg(msg, reload) {
      this.$swal({
        type: 'warning',
        title: 'Ops...',
        text: msg,
        //reiniciar?
        onClose: () => (reload ? location.reload() : {}),
      });
    },

    async carregarQuantidadeCuponsPeriodo() {
      const res = await this.obterQuantidadeCuponsPeriodo({ campanha: this.campaign, cpf: this.perfil.cpf });

      if (res.status !== 200 && res.status !== 404) {
        this.$swal({
          type: 'warning',
          title: 'Ops...',
          text: res.msg,
        });
      }
    },

    async carregaTotalCupomEscaneadoPorLote() {
      const res = await this.fetchTotalCupomEscaneadoPorLote({ campaign: this.campaign });

      if (res.status !== 200) {
        this.$swal({
          type: 'warning',
          text: res.mensagem,
        });
      }
    },

    async carregarQuantidadeRaspadinhasPeriodo() {
      const res = await this.obterQuantidadeRaspadinhasPeriodo({ campanha: this.campaign, cpf: this.perfil.cpf });

      if (res.status !== 200 && res.status !== 404) {
        this.$swal({
          type: 'warning',
          title: 'Ops...',
          text: res.msg,
        });
      }
    },
  },

  async mounted() {
    await this.carregaCampanhas();

    this.larguraJanela = window.innerWidth;
  },
};
</script>

<style>
.menu-alternative-height {
  height: 20% !important;
}
</style>
